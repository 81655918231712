const UA = navigator.userAgent;
const UAData = navigator.userAgentData;
let OS;
let browser;
let isMobileDevice = false;
let isSafari = false;


const dialog = document.querySelector('#dialog');
const dialogText = document.querySelector('#dialog-text');
const setSafariButton = document.querySelector('#set-safari');

if (navigator.maxTouchPoints > 0) {
    isMobileDevice = true;
}

// Works for Chrome and Edge
if (UAData !== undefined) {
    UAData.getHighEntropyValues(["platformVersion",]).then((values) => {
        OS = `${values.platform} ${values.platformVersion}`
        browser = `${values.brands[2].brand} ${values.brands[2].version}`;
    });
}
else {
    // Checks Firefox and Safari
    // Needs to find a way to get the right version of MacOS
    
    // Checks firefox iOS and macOS
    if (UA.includes('Firefox/') || UA.includes('FxiOS/')) {
        browser = 'Firefox'
        //Checks ipadOS
    } else if (isMobileDevice && UA.includes('Safari/') && !UA.includes('Mobile/')) {
        browser = 'Firefox'
    } else if (UA.includes('Edg/')) {
        browser = 'Edge'
    } else if (UA.includes('Chrome/') || UA.includes('CriOS/')) {
        browser = 'Google Chrome'
    } else if (UA.includes('Safari/')) {
        browser = 'Safari'
    }

    if (UA.includes('Mac')) {
        OS= 'macOS';
    }

    if (UA.includes('Windows')) {
        OS= 'Windows';
    }
}


if (browser === 'Safari') {
    isSafari = true;
}

document.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
        if (!isSafari) {
            dialog.classList.add('show-dialog');
            // setSafariButton.addEventListener('click', (e) => {
            //     window.open('x-apple.systempreferences:com.apple.Desktop-Settings.extension');  
            // })

            // Shows web Browser Version and Operation system
           // dialogText.innerHTML = `Operation System: ${UA}`;

        }
    }, 1000);
});
